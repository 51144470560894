// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"J4MmDdRphF9-RjBDyuSmG"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

const sentryEnvironment = () => {
  if (location.hostname.includes('localhost')) return 'localhost';
  else if (process.env.NEXT_PUBLIC_SENTRY_ENV)
    return process.env.NEXT_PUBLIC_SENTRY_ENV;
  else if (location.hostname.includes('app-portals-client-dev')) return 'dev';
  else if (location.hostname.includes('app-portals-client-qa')) return 'qa';
  else return 'prod';
};

console.log('Sentry environment: ' + sentryEnvironment());

Sentry.init({
  dsn: "https://ba08019859c2de8df438b41b0f7f608b@o4505001692626944.ingest.us.sentry.io/4508053686583296",

  environment: sentryEnvironment(),
  ignoreErrors: [
    'Event `CustomEvent` (type=error) captured as exception',
    'Object captured as promise rejection with keys: detailedMessage, errorCode, level',
  ],
  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: sentryEnvironment().includes('prod') ? 0.01 : 1,

  // Define how likely Replay events are sampled.
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0, // sentryEnvironment().includes('prod') ? 0.01 : 0.1,

  // Define how likely Replay events are sampled when an error occurs.
  replaysOnErrorSampleRate: sentryEnvironment().includes('prod') ? 0.01 : 1,

  integrations: [
    Sentry.extraErrorDataIntegration({
      depth: 5
    }),
    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: (sentryEnvironment().includes('prod') || sentryEnvironment().includes('qa')) ? true : false,
      blockAllMedia: true,
    }),
  ],
  beforeSend(event) {
    // Filter out custom events with type 'CustomEvent'
    if (event.message && event.type === 'error' && event.message.includes('CustomEvent')) {
      return null;
    }
    return event;
  },    

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
